:root {
  --fc-today-bg-color: #a8a8a820;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33.33% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-66 {
  width: 66.66% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.formulario {
  display: contents
}

.negrilla {
  font-weight: bold !important;
}

.css-1e7lj18 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.css-rvrop8-MuiSvgIcon-root {
  display: none !important;
}

.css-1acvgar-MuiTypography-root {
  font-size: 1.20rem !important;
}

.dateTimeField {
  width: 100% !important;
}

.rh5v-Volume_icon, .rh5v-PlayPause_icon, .rh5v-Fullscreen_icon {
  padding: 0px !important;
}



.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}


.formularioCitas .MuiFormControl-root{
  width: 100%;
}

.formularioCitas .Label-titleLabel {
  font-size: 16px !important;
  padding: 0px !important;
  margin: 0px !important  ;
}

.formularioCitas .MuiFormControl-marginNormal {
  margin: 0 !important;
}

.nluxc-chat-room-conversation-container {
  overflow: auto;
}

.nluxc-conversation-messages-container {
  overflow: auto;
}

.nluxc-chat-room-prompt-box-container {
  padding: 5px;
}

.nluxc-root .nluxc-text-message-container.nluxc-text-message-sent>.nluxc-text-message-content, .nluxc-root .nluxc-text-message-container.nluxc-text-message-sent>.nluxc-text-message-persona>.nluxc-text-message-persona-photo-container {
  background-color: #b99fd1 !important;
  border-color: #b99fd1 !important;
}

.nluxc-root>.nluxc-chat-room-container>.nluxc-chat-room-conversation-container>.nluxc-conversation-messages-container>.nluxc-conversation-welcome-message>.nluxc-conversation-welcome-message-photo-container>.nluxc-conversation-welcome-message-letter {
  z-index: 1 !important;
}

.nluxc-text-message-persona-letter {
  z-index: 1 !important;
}

.nluxc-conversation-welcome-message-rendered-photo {
  z-index: 1 !important;
}

.nluxc-text-message-persona-rendered-photo {
  z-index: 2 !important;
}

.nluxc-text-message-persona-photo-container {
  background: none !important;
}